<template>
  <div class="login-container">
    <!-- 导航栏 -->
    <van-nav-bar
      title="绑定手机号"
      left-arrow
      @click-left="$router.push({ name: '/' })"
      fixed
      color="#28B9AB"
    />

    <div class="bg">
      <img src="../static/bindphone.png" alt="" />
    </div>

    <ValidationObserver ref="form">

      <ValidationProvider name="手机号" rules="required|mobile">
        <van-cell-group>
        <van-field   left-icon="https://www.bjshiyi.cn/static/dlsjh.png" label="手机号" placeholder="请输入手机号" v-model="userID">

        </van-field>
        </van-cell-group>
      </ValidationProvider>

      <ValidationProvider name="验证码" rules="required|code">
        <van-cell-group>

        <van-field   left-icon="https://www.bjshiyi.cn/static/dxyzm.png" label="验证码" placeholder="请输入验证码" v-model="verCode">
          <van-count-down
            v-if="isCountDownShow"
            slot="button"
            :time="1000 * 60"
            format="ss s"
            @finish="isCountDownShow = false"
          >
          </van-count-down>
          <van-button
            v-else
            slot="button"
            size="small"
            type="primary"
            color="#28B9AB"
            @click="onSendSmsCode"
          >
            发送验证码
          </van-button>
        </van-field>
        </van-cell-group>
      </ValidationProvider>
    </ValidationObserver>

    <!-- /表单 -->
    <!-- 登陆按钮 -->
    <div class="bottom-box">
      <div class="btn-wrap">
        <van-button type="info" @click="OnLogin"> 登录 </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validate, localeChanged } from "vee-validate";
import { setItem, getItem, removeItem } from "@/utils/storage";


export default {
  name: "LoginPage",
  data() {
    return {
      isCountDownShow: false, // 是否显示倒计时
      userID: "",
      verCode: ""


    };
  },

  components: {},
  created() {

  },
  methods: {
  OnLogin() {
      //2表单验证
      this.$refs.form.validate().then((res) => {
        const success = res;
        // 失败提示错误信息
        if (!success) {
          setTimeout(() => {
            const errors = this.$refs.form.errors;
            const item = Object.values(errors).find((item) => item[0]);
            this.$toast(item[0]);
          }, 100);
          return;
        } else {
               this.$router.push("/welcome");
           
        }
      });

      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true,
      });
    },

    /**
     * 登录验证码
     */
    onSendSmsCode() {
      const mobile = this.userID;
      validate(mobile, "required|mobile", {
        name: "手机号",
      }).then((res) => {
        if (!res.valid) {
          this.$toast(res.errors[0]);
          return;
        }
        this.isCountDownShow = true;
        this.$axios({
          url: "/api/member/SendVerifyCode",
          method: "post",
          data: {
            userID: mobile,
          },
        })
          .then((res) => {
            this.$toast("验证码发送成功");
          })
          .catch((err) => {
            this.isCountDownShow = false;
            this.$toast("发送失败");
          });
      });
    },

    // 截取code
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },

  },

};
</script>

<style scoped lang="less">
.login-container {
  padding-top: 44px;
}
.bg {
  width: 100%;
  height: 178px;
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .van-nav-bar--fixed {
  background-color: #28b9ab;
  .van-nav-bar__title,
  .van-icon-arrow-left {
    color: #fff;
  }
}

.bottom-box {
  text-align: center;
  .btn-wrap {
    width: 282px;
    margin: 32px auto;

    .van-button {
      width: 100%;
      background-color: #28b9ab;
      border-radius: 10px;
      border: none;
    }
  }
  .weixin {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 41px;
      height: 41px;
    }
    span {
      padding-top: 10px;
    }
  }
}

.van-hairline--top-bottom::after
{

  border-width: 1px;
}
[class*=van-hairline]::after{
left: -40%;
  right: -40%;
  border: 0;
  border-bottom: 2px solid #E2E2E2;
}
</style>

